.container {
  position: relative;
  contain: strict;
  display: grid;
  height: rem(90);
  gap: rem(10);
  grid-template-columns: 1fr rem(90);

  @media (hover: hover) {
    &:hover {
      .tag {
        color: clr(b);
      }

      .title {
        color: clr(p-70);
      }
    }
  }

  @media bp(sm) {
    gap: 0;
    contain: content;
    border-radius: rem(6);
    display: flex;
    height: auto;
    flex-direction: column-reverse;

    .info {
      flex: 1;
      justify-content: flex-start;
      gap: rem(4);
      padding: rem(15);
      flex-direction: column-reverse;
    }

    .title {
      font-size: rem(18);
      line-height: rem(24);
    }

    .img {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      aspect-ratio: 16 / 9;
    }
  }
}

.overlayLink {
  white-space: nowrap;
  text-indent: 500%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.unresponsive {
  gap: 0;
  contain: content;
  border-radius: rem(6);
  display: flex;
  height: auto;
  flex-direction: column-reverse;

  .info {
    flex: 1;
    justify-content: flex-start;
    gap: rem(4);
    padding: rem(15);
    flex-direction: column-reverse;
  }

  .title {
    font-size: rem(18);
    line-height: rem(24);
  }

  .img {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    aspect-ratio: 16 / 9;
  }

  .scoreLine {
    transform-origin: 100% 100%;
    color: clr(p-70);
    background-color: clr(w);
    border-radius: rem(2);
    padding: rem(6) rem(10);
    position: absolute;
    left: rem(10);
    top: rem(10);
  }

  .scoreRing {
    position: absolute;
    left: rem(8);
    right: auto;
    top: rem(8);
    width: rem(36);
    height: rem(36);
    font-size: rem(13);
  }
}

.info {
  display: flex;
  gap: rem(3);
  flex-direction: column;
}

.tag {
  text-transform: capitalize;
  font-size: rem(12);
  line-height: rem(14);
  color: clr(g-40);

  @mixin transition color;
}

.title {
  line-height: rem(20);
  font-weight: bold;

  @mixin transition color;
  @mixin lineClamp 3;
}

.commentsCounter {
  color: clr(g-25);
  font-size: rem(12);
  line-height: rem(16);
}

.img {
  width: rem(90);
  height: rem(90);
}

.score {
  position: absolute;
  padding: rem(6) rem(10);
  border-radius: rem(2);
  left: rem(10);
  top: rem(10);
  color: clr(p-70);
  background-color: clr(w);
}

.scoreLine {
  display: none;

  @media bp(sm) {
    transform-origin: 100% 100%;
    color: clr(p-70);
    background-color: clr(w);
    border-radius: rem(2);
    padding: rem(6) rem(10);
    position: absolute;
    left: rem(10);
    top: rem(10);
  }
}

.scoreRing {
  display: none;

  @media bp(sm) {
    position: absolute;
    left: rem(8);
    top: rem(8);
    width: rem(36);
    height: rem(36);
    font-size: rem(13);
  }
}

.badge {
  width: rem(40);
  height: rem(60);
  position: absolute;
  right: rem(10);
  top: rem(10);

  @media bp(md) {
    width: rem(72);
    height: rem(110);

    &.spaced {
      top: rem(30);
      right: rem(30);
    }
  }
}

.badgeImage {
  background-color: transparent;
  object-fit: contain;
  object-position: right top;
}
