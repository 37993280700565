.container {
  display: flex;
  border-bottom: bdr(1, g-90);
}

.tab {
  font-size: rem(22);
  line-height: 1;
  font-weight: bold;
  height: rem(43);
  padding: 0 rem(15);
  border: bdr(1, g-90);
  color: clr(b/20);
  transition: trn(background-color);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: rem(2);
    left: rem(15);
    right: rem(15);
    bottom: 0;
    background-color: clr(b);
    transition: trn(opacity);
    opacity: 0;
  }
}

.current {
  color: clr(b);
  background-color: clr(g-90);

  &::after {
    opacity: 1;
  }
}
