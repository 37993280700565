.container {
  overflow-wrap: anywhere;
  background-color: clr(g-90);
  border-radius: rem(8);
  display: flex;
  font-size: rem(18);
  line-height: rem(20);
  padding: 0 rem(20);
  font-weight: bold;
  min-height: rem(44);
  align-items: center;
  transition: trn(box-shadow);

  @media bp(sm) {
    background-color: transparent;
    border-radius: rem(35);
    min-height: rem(46);
    border: bdr(1, g-90);
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: shadow(tg, b/25);
    }
  }
}

.label {
  flex: 1;
}

.icon {
  flex-shrink: 0;
  width: rem(7);
  height: rem(12);

  @media bp(sm) {
    display: none;
  }
}
