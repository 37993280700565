.leaderboardFirst {
  margin-top: rem(20);

  @media bp(lg) {
    margin-top: rem(50);
  }
}

.leaderboardSecond {
  margin-top: rem(15);

  @media bp(lg) {
    margin-top: rem(70);
  }
}
