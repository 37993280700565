.container {
  border-bottom: bdr(1, g-90);

  @media bp(sm) {
    display: flex;
    align-items: baseline;
    border-bottom: none;
  }
}

.title {
  display: flex;
  font-size: rem(18);
  line-height: rem(24);
  font-weight: bold;
  align-items: center;
  column-gap: rem(10);

  &::after {
    content: '';
    flex: 1;
    height: rem(1);
    background-color: clr(g-90);
  }

  @media bp(sm) {
    flex-shrink: 0;

    &::after {
      flex-shrink: 0;
      content: '';
      height: rem(2);
      background-color: clr(g-60);
      min-width: rem(20);
      margin-right: rem(10);
    }
  }
}

.hiddenMobile {
  display: none;

  @media bp(sm) {
    display: flex;
  }
}
