.tabs {
  margin-bottom: rem(20);

  @media bp(lg) {
    display: none !important;
  }
}

.grid {
  @media bp(lg) {
    grid-template-columns: 62fr 28fr;
    display: grid;
    row-gap: rem(30);
    column-gap: rem(60);
    align-items: flex-start;
  }
}

.title {
  display: none;

  @media bp(lg) {
    display: block;
    font-size: rem(32);
    line-height: rem(40);
    font-weight: bold;
  }
}

.list {
  display: none;

  &.listActive {
    display: grid;
  }

  @media bp(lg) {
    display: grid;
  }
}

.leftList {
  row-gap: rem(15);

  @media bp(lg) {
    row-gap: rem(30);
  }
}

.rightList {
  row-gap: rem(15);

  @media bp(lg) {
    row-gap: rem(18);
  }
}
