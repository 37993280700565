.hero {
  margin-top: rem(50);

  @media bp(lg) {
    display: grid;
    grid-template-columns: 52fr 77fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: column;
    margin-top: rem(85);
  }
}

.title {
  font-size: rem(28);
  line-height: rem(34);
  font-weight: bold;

  @media bp(lg) {
    font-size: rem(60);
    line-height: rem(72);
  }
}

.content {
  margin-top: rem(50);

  @media bp(lg) {
    margin-top: rem(20);
    padding-left: rem(30);
    grid-row: span 2;
    border-left: bdr(1, b);
  }
}

.subTitle {
  font-size: rem(24);
  line-height: rem(32);
  font-weight: bold;
}

.text {
  margin-top: rem(10);
}

.breadcrumbs {
  margin-top: rem(5);

  @media bp(lg) {
    margin-top: rem(10);
  }
}

.categoriesBlock {
  margin-top: rem(20);

  @media bp(lg) {
    margin-top: rem(35);
  }
}

.categoriesGrid {
  display: flex;
  flex-direction: column;
  row-gap: rem(5);
  padding: rem(10) 0;

  @media bp(sm) {
    padding: rem(10) 0 rem(20) 0;
    flex-flow: row wrap;
    row-gap: rem(15);
    column-gap: rem(15);
  }
}

.leaderboardFirst {
  margin-top: rem(20);

  @media bp(lg) {
    margin-top: rem(50);
  }
}

.leaderboardSecond {
  margin-top: rem(15);

  @media bp(lg) {
    margin-top: rem(70);
  }
}

.categoryTitle {
  font-size: rem(24);
  line-height: rem(32);
  font-weight: bold;
  margin-top: rem(30);

  &:first-of-type {
    margin-top: rem(20);
  }

  @media bp(lg) {
    font-size: rem(32);
    margin-top: rem(60);
    line-height: rem(40);

    &:first-of-type {
      margin-top: rem(50);
    }
  }
}

.lazy {
  min-height: rem(310);
}

.slider {
  @media bp(lg) {
    margin-top: rem(20);
  }
}

.slide {
  height: auto !important;
  padding: rem(15) 0;
}

.card {
  height: 100%;
  background-color: clr(darkable-w);
  box-shadow: shadow(tf, b/15);
  transition: trn(box-shadow, transform);

  @media (hover: hover) {
    &:hover {
      box-shadow: shadow(tc, b/15);
      transform: scale(1.03, 1.03);
    }
  }
}

.bottom {
  margin-top: rem(30);

  @media bp(lg) {
    margin-top: rem(70);
  }
}

.postTiny {
  @media bp(lg) {
    padding-left: rem(20);
    border-left: bdr(2, b/15);
  }
}

.list {
  margin-top: rem(15);
  display: flex;
  flex-direction: column;

  @media bp(lg) {
    margin-top: rem(30);
  }
}

.sidebar {
  display: grid;
  row-gap: rem(20);
}

.stickySideBarAd{
  position: sticky;
  top: rem(20);
}
